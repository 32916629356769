<template>
  <div>
    <div class="header">
      <img :src="institusi ? institusi.logo : logo" class="logo" />
      <a-menu mode="horizontal" style="
        padding: 20px;
        padding-left: 50px;
        padding-right: 50px;">
        <a-menu-item key="/beranda">
          <a>Beranda</a>
        </a-menu-item>
        <a-menu-item key="/informasi-pendaftaran">
          <a @click.prevent="scrollToSection('section2')">Informasi Pendaftaran</a>
        </a-menu-item>
        <a-menu-item key="/alur-pendaftaran">
          <a @click.prevent="scrollToSection('section3')">Alur Pendaftaran</a>
        </a-menu-item>
        <a-menu-item key="/biaya">
          <a @click.prevent="scrollToSection('section4')">Biaya</a>
        </a-menu-item>
        <router-link target="_blank" :to="{ name: 'PpdbSwasta' }">
          <a-button type="primary" class="btn-navbar">Daftar</a-button>
        </router-link>
      </a-menu>
      <div class="icon-menu" @click.prevent="() => toggle = !toggle"><a-icon type="menu" /></div>
    </div>
    <div class="menu-dropdown" v-if="toggle">
      <span>Beranda</span>
      <span @click.prevent="scrollToSection('section2')">Informasi Pendaftaran</span>
      <span @click.prevent="scrollToSection('section3')">Alur Pendaftaran</span>
      <span @click.prevent="scrollToSection('section4')">Biaya</span>
      <a-button type="primary" class="btn-navbar">Daftar</a-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      modalImage: 0,
      toggle: false,
    }
  },
  computed: {
    institusi() {
      return this.$store.state.master.institusi
    },
  },
  methods: {
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    },
    onChange(a, b, c) {
      console.log(a, b, c)
    },
    showModal(idx) {
      this.visible = true
      this.modalImage = idx
    },
    handleOk(e) {
      console.log(e)
      this.visible = false
    },
  },
}
</script>
<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* justify-content: center; */
  /* background-color: aqua; */
  padding-left: 125px;
  /* background-color: aqua; */
  padding-right: 100px;
}

.ant-menu-horizontal .ant-menu-item a {
  color: #00373E;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  /* 24px */
  font-family: 'Raleway', sans-serif;
}

.btn-navbar {
  margin-left: 20px;
}

.logo {
  width: 75px;
  height: 75px;
  /* margin-left: 125px; */
  margin-top: 10px;
}

.icon-menu {
  display: none;
}

.menu-dropdown {
  display: none;
}

@media screen and (max-width: 1024px) {
  .header {
    padding-left: 25px;
    padding-right: 25px;
    /* background-color: #24691b; */
  }

  .btn-navbar {
    margin-top: 10px;
    margin-left: 0;
  }
}

@media screen and (max-width: 790px) {
  .icon-menu {
    display: block;
  }

  .ant-menu-horizontal .ant-menu-item a {
    display: none;
    font-size: 5px !important;
  }

  .ant-menu {
    background-color: red;
    width: 100px;
    display: none;
  }

  .btn-navbar {
    display: none;
  }

  .menu-dropdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 150px;
    background-color: #24691b;
  }

  .menu-dropdown span {
    font-size: 20px;
    color: white;
    font-family: Montserrat;
    /* font-size: 12px !important; */
    font-weight: 600;
    width: auto;
    border-bottom: 1px solid white;
  }

  .menu-dropdown span:hover {
    color: rgb(94, 227, 123);
  }

}
</style>
